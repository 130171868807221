import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { appInitLoader } from './app-init.loaders';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
var ɵ0 = appInitLoader;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
// required for AOT compilation
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}
export { ɵ0 };
