import * as tslib_1 from "tslib";
import { BaseService, Dictionary, ServiceController, ServiceAction } from 'helio-games-core';
var ChangePasswordService = /** @class */ (function (_super) {
    tslib_1.__extends(ChangePasswordService, _super);
    function ChangePasswordService(http, appConfigService, authService) {
        var _this = _super.call(this, http, ServiceController.AGENT_PROFILE, appConfigService.agentServiceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        _this.authService = authService;
        return _this;
    }
    ChangePasswordService.prototype.changePassword = function (oldPassword, newPassword) {
        var requestHeaders = {
            headersValues: new Dictionary({
                'Authorization': this.authService.authTokenData.token_type + " " + this.authService.authTokenData.access_token
            }),
            appendToDefaultHeaders: true
        };
        var data = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        return this.edit(ServiceAction.CHANGE_PASSWORD, data, undefined, requestHeaders);
    };
    return ChangePasswordService;
}(BaseService));
export { ChangePasswordService };
