import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ComponentHostDirective } from './shared/directives';
var AppComponent = /** @class */ (function () {
    function AppComponent(appOverlaysService, appLayoutService, windowEventsService, authService, router) {
        var _this = this;
        this.appOverlaysService = appOverlaysService;
        this.appLayoutService = appLayoutService;
        this.windowEventsService = windowEventsService;
        this.authService = authService;
        this.router = router;
        this.appVersion = VERSION;
        this.isMobile = false;
        this.isLoading = false;
        this.secondsInactive = 0;
        this.INACTIVITY_TIMEOUT_SECONDS = 300;
        this.onTouchStart = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.secondsInactive = 0;
                return [2 /*return*/];
            });
        }); };
        this.onMouseMove = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.secondsInactive = 0;
                return [2 /*return*/];
            });
        }); };
        this.onMouseClick = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.secondsInactive = 0;
                return [2 /*return*/];
            });
        }); };
        this.startCheckingForActivity = function () {
            setInterval(function () {
                _this.secondsInactive++;
                if (_this.secondsInactive > _this.INACTIVITY_TIMEOUT_SECONDS) {
                    _this.authService.logout();
                }
            }, 1000);
        };
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setHtmlFontSize();
        this.windowEventsService.onResize
            .subscribe(function () { return _this.setHtmlFontSize(); });
        this.startCheckingForActivity();
        this.appLoadingHandler();
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        this.appOverlaysService.overlayHost = this.overlayHost;
    };
    AppComponent.prototype.appLoadingHandler = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.isLoading = true;
            }
            else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                _this.isLoading = false;
            }
        });
    };
    AppComponent.prototype.setHtmlFontSize = function () {
        var windowWidth = window.innerWidth;
        var html = document.getElementsByTagName('html')[0];
        var fontSize = this.appLayoutService.getFontSize(windowWidth);
        this.appLayoutService.isMobile = (windowWidth < 887);
        this.isMobile = this.appLayoutService.isMobile;
        html.style.fontSize = fontSize + "%";
    };
    return AppComponent;
}());
export { AppComponent };
