import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { BaseService, Dictionary } from 'helio-games-core';
import { environment } from '../../../environments/environment';
import { AuthToken } from '../models/auth-token.model';
var AuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthService, _super);
    function AuthService(http, appConfigService, appOverlaysService, agentBalanceService, gameConfigService, windowEventsService, router) {
        var _this = _super.call(this, http, 'oauth2', appConfigService.agentServiceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        _this.appOverlaysService = appOverlaysService;
        _this.agentBalanceService = agentBalanceService;
        _this.gameConfigService = gameConfigService;
        _this.windowEventsService = windowEventsService;
        _this.router = router;
        _this.authTokenData = null;
        _this.isUserActive = false;
        var localStorageToken = localStorage.getItem('agentAppToken');
        if (localStorageToken !== null) {
            _this.saveToken(JSON.parse(localStorageToken), false);
        }
        _this.isUserActiveHandler();
        return _this;
    }
    AuthService.prototype.login = function (username, password) {
        var _this = this;
        var data = {
            username: username,
            password: password,
            grant_type: 'password'
        };
        var requestHeader = {
            headersValues: new Dictionary({
                'Authorization': this.getAuthorisationHeaderValue('basic'),
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            appendToDefaultHeaders: false
        };
        return this.post('/token', data, undefined, requestHeader)
            .pipe(map(function (res) {
            _this.saveToken(res);
            return _this.authTokenData;
        }), catchError(function () {
            return of(null);
        }));
    };
    AuthService.prototype.refreshUserToken = function (autoLogout) {
        var _this = this;
        if (autoLogout === void 0) { autoLogout = true; }
        var data = {
            refresh_token: this.authTokenData.refresh_token,
            grant_type: 'refresh_token',
            client_id: environment.authClientID
        };
        var requestHeader = {
            headersValues: new Dictionary({
                'Authorization': this.getAuthorisationHeaderValue('basic'),
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            appendToDefaultHeaders: false
        };
        return this.post('/token', data, undefined, requestHeader)
            .pipe(map(function (res) {
            _this.saveToken(res);
        }), catchError(function () {
            if (autoLogout) {
                _this.logout();
            }
            return of(null);
        }));
    };
    AuthService.prototype.logout = function () {
        localStorage.removeItem('agentAppToken');
        this.authTokenData = null;
        localStorage.removeItem('agentAppBalance');
        this.appOverlaysService.clearOverlays();
        this.router.navigate(['login']);
    };
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        get: function () {
            if (this.authTokenData !== null) {
                var now = new Date();
                return (now < this.authTokenData.tokenExpiresDate);
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isInactivityDateLimitExpired", {
        get: function () {
            if (this.authTokenData !== null) {
                var now = new Date();
                var inactiveDateLimit = this.authTokenData.inactiveDateLimit;
                return (now >= inactiveDateLimit);
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "canRefreshToken", {
        get: function () {
            if (this.authTokenData !== null) {
                var now = new Date();
                var refreshTokenExpires = this.authTokenData['.expires'];
                return (now < refreshTokenExpires);
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.sessionExpiredHandler = function () {
        var _this = this;
        return of(true)
            .pipe(delay(this.authTokenData.tokenExpiresDate), map(function () {
            if (_this.isUserActive) {
                _this.refreshUserToken().subscribe(function () {
                    _this.sessionExpiredHandlerSubscription = _this.sessionExpiredHandler().subscribe();
                });
            }
        }));
    };
    AuthService.prototype.saveToken = function (authResponse, newToken) {
        if (newToken === void 0) { newToken = true; }
        this.authTokenData = new AuthToken(authResponse, newToken);
        if (localStorage.getItem('agentAppBalance') !== null) {
            this.agentBalanceService.balance = +localStorage.getItem('agentAppBalance');
        }
        else {
            this.agentBalanceService.balance = (this.authTokenData.payload.Balance !== undefined && this.authTokenData.payload.Balance !== null) ?
                this.authTokenData.payload.Balance : 0;
        }
        this.gameConfigService.gameConfig = {
            apiKey: this.authTokenData.payload.APIKey,
            currencyCode: this.authTokenData.payload.CurrencyCode
        };
    };
    AuthService.prototype.isUserActiveHandler = function () {
        var _this = this;
        this.windowEventsService.onFocus.subscribe(function () {
            _this.isUserActive = true;
            // if token is valid (i.e. not expired)
            if (_this.isLoggedIn) {
                if (_this.sessionExpiredHandlerSubscription === undefined) {
                    _this.sessionExpiredHandlerSubscription = _this.sessionExpiredHandler().subscribe();
                }
            }
            else {
                // check if session can be refreshed
                if (_this.canRefreshToken) {
                    _this.refreshUserToken().subscribe(function () {
                        _this.unsubscribeSessionExpiredHandler();
                        _this.sessionExpiredHandlerSubscription = _this.sessionExpiredHandler().subscribe();
                    });
                }
                else {
                    _this.logout();
                }
            }
        });
        this.windowEventsService.onBlur.subscribe(function () {
            _this.isUserActive = false;
            _this.unsubscribeSessionExpiredHandler();
        });
    };
    AuthService.prototype.unsubscribeSessionExpiredHandler = function () {
        if (this.sessionExpiredHandlerSubscription !== undefined) {
            this.sessionExpiredHandlerSubscription.unsubscribe();
        }
    };
    AuthService.prototype.getAuthorisationHeaderValue = function (type) {
        if (type === void 0) { type = 'bearer'; }
        var authHeaderToken = btoa(environment.authClientID + ":" + environment.authClientSecret);
        return type + " " + authHeaderToken;
    };
    return AuthService;
}(BaseService));
export { AuthService };
