import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "helio-games-core";
import * as i3 from "./auth.service";
var DepositService = /** @class */ (function () {
    function DepositService(http, appConfigService, authService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.authService = authService;
    }
    DepositService.prototype.createDeposit = function (gatewayName, currencyCode, idempotencyKey) {
        //
        var url = this.appConfigService.agentServiceBaseURL + "api/deposits";
        var authHeaderValue = this.authService.authTokenData.token_type + " " + this.authService.authTokenData.access_token;
        return this.http.post(url, {
            gatewayName: gatewayName, currencyCode: currencyCode, idempotencyKey: idempotencyKey
        }, {
            headers: { 'Authorization': authHeaderValue }
        }).toPromise();
    };
    DepositService.prototype.getIdempotencyKey = function () {
        var url = this.appConfigService.agentServiceBaseURL + "api/deposits/idempotency";
        return this.http.get(url).toPromise();
    };
    DepositService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DepositService_Factory() { return new DepositService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.AuthService)); }, token: DepositService, providedIn: "root" });
    return DepositService;
}());
export { DepositService };
