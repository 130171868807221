import * as tslib_1 from "tslib";
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
var languageCodeToLoad;
export function appInitLoader(appConfigService, appLangService, translateService, capabilities, pwaService) {
    var _this = this;
    return function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    beforeInstallPromptHandler(pwaService);
                    return [4 /*yield*/, appConfigLoader(appConfigService)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, loadLanguage(appConfigService, translateService, appLangService)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, dynamicLanguageLoad(appConfigService, appLangService)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, addHelioGamesJavascriptLibrary()];
                case 4:
                    _a.sent();
                    return [2 /*return*/, Promise.resolve()];
            }
        });
    }); };
}
function beforeInstallPromptHandler(pwaService) {
    window.addEventListener('beforeinstallprompt', function (e) {
        e.preventDefault();
        pwaService.promptEvent = e;
    });
}
function appConfigLoader(appConfigService) {
    return new Promise(function (resolve) {
        var gameServer = '';
        var tenantServer = getTenantServer();
        tenantServer = (tenantServer == '0') ? environment.defaultTenant : tenantServer;
        switch (environment.env) {
            case 'prod':
                gameServer = "prod-" + tenantServer;
                break;
            case 'stage':
                gameServer = "stg-" + tenantServer;
                break;
            case 'qa':
                gameServer = "qa-" + tenantServer;
                break;
            case 'dev-server':
                gameServer = "dev-1";
                break;
            case 'dev':
            default:
                gameServer = 'stg-1';
                break;
        }
        appConfigService.loadAppConfig(gameServer)
            .then(function () { return resolve(); });
    });
}
//get tenant server, and if no tenant server is found, then return a default of 1
function getTenantServer() {
    var currentURL = window.location.href;
    var regex = /-ts([0-9]+)./;
    if (currentURL.match(regex) != null)
        return currentURL.match(regex)[1];
    else
        return "0";
}
function loadLanguage(appConfigService, translateService, appLangService) {
    return new Promise(function (resolve) {
        translateService.setDefaultLang('en');
        languageCodeToLoad = localStorage.getItem('agentAppLang');
        if (appConfigService.supportedLanguages === undefined) {
            console.warn("App Config does not specify supportedLanguages. Game Language will default to English.");
            languageCodeToLoad = 'en';
        }
        else if (languageCodeToLoad !== null && languageCodeToLoad !== undefined && languageCodeToLoad !== '') {
            languageCodeToLoad = getSupportedLanguageCode(languageCodeToLoad, appConfigService.supportedLanguages);
        }
        else {
            languageCodeToLoad = 'en';
        }
        if (languageCodeToLoad !== 'en') {
            translateService.use(languageCodeToLoad)
                .subscribe(function () {
                // console.log('load language successful');
            }, function (error) {
                console.log('load language failed');
                console.log(error);
                // load default language
                translateService.use('en').subscribe(function () {
                    languageCodeToLoad = 'en';
                    resolve();
                });
            }, function () {
                appLangService.selectedLanguage = languageCodeToLoad;
                resolve();
            });
        }
        else {
            // load default language
            translateService.use('en').subscribe(function () {
                languageCodeToLoad = 'en';
                appLangService.selectedLanguage = languageCodeToLoad;
                resolve();
            });
        }
    });
}
function dynamicLanguageLoad(appConfigService, appLangService) {
    return new Promise(function (resolve) {
        var localeID = languageCodeToLoad;
        if (appConfigService.supportedLanguages === undefined) {
            console.warn("App Config does not specify supportedLanguages. Game Locale will default to en-GB.");
        }
        else {
            localeID = getSupportedLanguageCode(localeID, appConfigService.supportedLanguages);
        }
        if (localeID !== 'en') {
            import(
            /* webpackInclude: /\/|\\(en|fa|fr|lg|sg).*\.js$/, webpackChunkName: "locale-data/[request]" */
            "@angular/common/locales/" + localeID + ".js")
                .then(function (importedModule) {
                registerLocaleData(importedModule.default, localeID);
                appLangService.selectedLocaleID = localeID;
            }).catch(function () {
                loadDefaultLocale(appLangService);
            }).then(function () {
                resolve();
            });
        }
        else {
            loadDefaultLocale(appLangService);
            resolve();
        }
    });
}
function loadDefaultLocale(appLangService) {
    import("@angular/common/locales/en-GB.js")
        .then(function (importedModule) {
        registerLocaleData(importedModule.default, 'en-GB');
        appLangService.selectedLocaleID = 'en-GB';
    });
}
function getSupportedLanguageCode(gameConfigLanguageCode, supportedLanguages) {
    var isLanguageSupported = supportedLanguages.indexOf(gameConfigLanguageCode) !== -1;
    if (isLanguageSupported) {
        return gameConfigLanguageCode;
    }
    if (!isLanguageSupported && gameConfigLanguageCode.length > 2) {
        var tempLanguageCode = gameConfigLanguageCode.substring(0, 2);
        isLanguageSupported = supportedLanguages.indexOf(tempLanguageCode) !== -1;
        if (isLanguageSupported) {
            return tempLanguageCode;
        }
    }
    return 'en';
}
function addHelioGamesJavascriptLibrary() {
    return new Promise(function (resolve) {
        var helioScript = document.createElement('script');
        helioScript.type = 'text/javascript';
        helioScript.src = environment.helioGameJavaScriptUrl;
        document.body.appendChild(helioScript);
        helioScript.onload = function () {
            resolve();
        };
    });
}
