import { Subject } from 'rxjs';
var AgentBalanceService = /** @class */ (function () {
    function AgentBalanceService() {
        this.agentBalance = 0;
        this.agentBalanceSubject = new Subject();
        this.onAgentBalanceChange = this.agentBalanceSubject.asObservable();
    }
    Object.defineProperty(AgentBalanceService.prototype, "balance", {
        get: function () {
            return this.agentBalance;
        },
        set: function (newAgentBalance) {
            this.agentBalance = newAgentBalance;
            localStorage.setItem('agentAppBalance', newAgentBalance.toString());
            this.agentBalanceSubject.next(newAgentBalance);
        },
        enumerable: true,
        configurable: true
    });
    return AgentBalanceService;
}());
export { AgentBalanceService };
