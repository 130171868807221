import * as i0 from "@angular/core";
var CapabilitiesService = /** @class */ (function () {
    function CapabilitiesService() {
        var _this = this;
        this._capabilities = new Map();
        this.setCapabilities = function (capabilities) {
            capabilities.forEach(function (c) {
                var v = _this.parseType(c.value, c.type);
                _this._capabilities.set(c.name, v);
            });
            console.log("Agent capabilities: " + capabilities.map(function (c) { return c.name + ":" + c.value; }).join(','));
        };
        this.getCapability = function (name) {
            return _this._capabilities.get(name);
        };
        this.parseType = function (value, type) {
            switch (type) {
                case 'bool': {
                    return value === "true";
                }
                case 'int': {
                    return parseInt(value);
                }
                case 'string': {
                    return value;
                }
            }
        };
    }
    CapabilitiesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CapabilitiesService_Factory() { return new CapabilitiesService(); }, token: CapabilitiesService, providedIn: "root" });
    return CapabilitiesService;
}());
export { CapabilitiesService };
