import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';
var AuthToken = /** @class */ (function () {
    function AuthToken(jwt, newToken) {
        if (newToken === void 0) { newToken = true; }
        this.access_token = jwt.access_token;
        this.token_type = jwt.token_type;
        this.expires_in = jwt.expires_in;
        this.refresh_token = jwt.refresh_token;
        this.client_id = jwt.client_id;
        this.userName = jwt.userName;
        this['.issued'] = new Date(jwt['.issued']);
        this['.expires'] = new Date(jwt['.expires']);
        this.setTokenExpiresDate(newToken, jwt);
        this.getPayload();
        this.saveToLocalStorage();
    }
    Object.defineProperty(AuthToken.prototype, "inactiveDateLimit", {
        get: function () {
            return this.userInactiveDateLimit;
        },
        enumerable: true,
        configurable: true
    });
    AuthToken.prototype.setInactiveDateLimit = function () {
        var now = new Date();
        this.userInactiveDateLimit = new Date(now.setSeconds(now.getSeconds() + environment.inactiveTimeLimit));
        this.saveToLocalStorage();
    };
    AuthToken.prototype.setTokenExpiresDate = function (isNewToken, oldToken) {
        if (isNewToken === void 0) { isNewToken = true; }
        if (isNewToken) {
            var now = new Date();
            // this.tokenExpiresDate = new Date(tokenExpires.toISOString());
            this.tokenExpiresDate = new Date(now.setSeconds(now.getSeconds() + this.expires_in));
        }
        else {
            this.tokenExpiresDate = new Date(oldToken.tokenExpiresDate);
        }
    };
    AuthToken.prototype.getPayload = function () {
        var accessTokenParts = this.access_token.split('.');
        // As per JWT format, the first str preceding the first dot is header, and the str after is the payload
        this.payload = JSON.parse(Buffer.from(accessTokenParts[1], 'base64').toString());
    };
    AuthToken.prototype.saveToLocalStorage = function () {
        localStorage.setItem('agentAppToken', JSON.stringify(this));
    };
    return AuthToken;
}());
export { AuthToken };
