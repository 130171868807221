import { Subject } from 'rxjs';
var AppLayoutService = /** @class */ (function () {
    function AppLayoutService() {
        this.mobileVersionChange = new Subject();
        this.isMobileVersion = false;
    }
    AppLayoutService.prototype.getFontSize = function (windowWidth) {
        var defaultFontSize = 62.5;
        if (windowWidth > 887) {
            return (windowWidth / 1920) * defaultFontSize;
        }
        else if (windowWidth <= 887 && windowWidth > 600) {
            return (windowWidth / 1920) * 87.5;
        }
        else if (windowWidth <= 600 && windowWidth > 425) {
            return (windowWidth / 1920) * 120;
        }
        else {
            return (windowWidth / 1920) * 175;
        }
    };
    Object.defineProperty(AppLayoutService.prototype, "onMobileVersionChange", {
        get: function () {
            return this.mobileVersionChange.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppLayoutService.prototype, "isMobile", {
        get: function () {
            return this.isMobileVersion;
        },
        set: function (isMobileVersion) {
            this.isMobileVersion = isMobileVersion;
            this.mobileVersionChange.next(isMobileVersion);
        },
        enumerable: true,
        configurable: true
    });
    return AppLayoutService;
}());
export { AppLayoutService };
