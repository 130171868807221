import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { BuyButtonComponent, BuyTicketData, GameService, TicketSelectedItems, BuyTicketMultiDraws, GameUtilities, GameFinanicalRulePrice, BuyButtonEmitData, AgentPlaceBetResponse, GameGroup, GameRuleBoard, GameFinancialRulesService, AgentCoupon, GameFinancialRulePrizeCategory } from 'helio-games-core';
import { PrintTicketComponent } from './print-ticket';
import { DRAW_DROPDOWN_ITEMS } from '../coupon-picker/draw-dropdown.data';
import { AlertComponent } from '../../alert';
import { CommentModalComponent } from 'src/app/comment-modal';
import * as _ from 'lodash';
var PurchasePrintComponent = /** @class */ (function () {
    function PurchasePrintComponent(gameService, gameFinancialRulesService, buyTicketService, authService, appOverlaysService, errorHandlerService, agentBalanceService, appLangService, translateService, capabilities) {
        var _this = this;
        this.gameService = gameService;
        this.gameFinancialRulesService = gameFinancialRulesService;
        this.buyTicketService = buyTicketService;
        this.authService = authService;
        this.appOverlaysService = appOverlaysService;
        this.errorHandlerService = errorHandlerService;
        this.agentBalanceService = agentBalanceService;
        this.appLangService = appLangService;
        this.translateService = translateService;
        this.capabilities = capabilities;
        this.comment = '';
        this.showCommentModal = false;
        this.ticketSelectedItems = [];
        this.ticketCost = 0;
        this.linePrice = 0;
        this.maxWin = 0;
        this.canShowMaxWin = false;
        this.currencyCode = '';
        this.consecutiveDraws = 1;
        this.selectedGames = [];
        this.scrollIntoView = new EventEmitter();
        this.isBetLoading = false;
        this.consecutiveDrawsItems = DRAW_DROPDOWN_ITEMS;
        this.selectedConsecutiveDraws = [DRAW_DROPDOWN_ITEMS[0]];
        this.multiBetsAllowed = false;
        this.comboBetsAllowed = false;
        this.getMaxWin = function () {
            var currentTotal = _this.getMaxWinFromSelectedTickets();
            var tickets = _this.ticketSelectedItems;
            var gameTiers = _.uniq(tickets.map(function (t) { return t.tierName; }));
            var financialRules = gameTiers.map(function (t) { return _this.getGameFinancialRuleFromTierName(t); });
            var currentFinancialRule = _this.buyTicketService.buyTicketGameData.gameFinancialRule;
            if (!financialRules.find(function (t) { return t.gameFinancialRuleID === currentFinancialRule.gameFinancialRuleID; })) {
                currentTotal += _this.getMaxWinFromFinancialRule(currentFinancialRule.gameFinancialRuleID);
            }
            return currentTotal;
        };
        this.getMaxWinFromFinancialRule = function (financialRuleID) {
            if (financialRuleID === void 0) { financialRuleID = undefined; }
            var financialRule = financialRuleID === undefined
                ? _this.buyTicketService.buyTicketGameData.gameFinancialRule
                : _this.gameFinancialRulesService.gameFinancialRules.find(function (f) { return f.gameFinancialRuleID === financialRuleID; });
            var jackpots;
            var maxPrize = 0;
            if (financialRule.prizeCategories.length > 0) {
                jackpots = financialRule.prizeCategories.find(function (p) { return p.prizeCategoryID === 1; });
                if (jackpots !== undefined) {
                    var prize = jackpots.amounts.find(function (p) { return p.currencyCode === _this.currencyCode; });
                    maxPrize = prize.prizeAmount == null
                        ? prize.prizeAmount
                        : prize.minPrizeAmount;
                }
            }
            return maxPrize;
        };
        this.getMaxWinFromSelectedTickets = function () {
            var tickets = _this.ticketSelectedItems;
            var gameTiers = _.uniq(tickets.map(function (t) { return t.tierName; }));
            var financialRules = gameTiers.map(function (t) { return _this.getGameFinancialRuleFromTierName(t); });
            return financialRules.reduce(function (total, rule) {
                var jackpot = rule.prizeCategories.find(function (prize) { return prize.prizeCategoryID === 1; });
                var jackpotInSelectedCurrency = jackpot.amounts.find(function (j) { return j.currencyCode === _this.currencyCode; });
                var prize = jackpotInSelectedCurrency.prizeAmount == null
                    ? jackpotInSelectedCurrency.prizeAmount
                    : jackpotInSelectedCurrency.minPrizeAmount;
                return total + prize;
            }, 0);
        };
        this.getBetCombinationSize = function () {
            var result = 0;
            var tickets = _this.ticketSelectedItems;
            tickets.forEach(function (ticket) {
                result += _this.getTicketCombinationSize(ticket);
            });
            return result;
        };
        this.getTicketCombinationSize = function (ticket) {
            var combinations = 1;
            var boards = ticket.selectedItems;
            boards.forEach(function (b, index) {
                var couponSize = b.pickedNumbers.length;
                var normalColumnAmount = _this.gameRuleBoards[index].numColumnsCoupon;
                if (couponSize > normalColumnAmount) {
                    combinations *= _this.getNumberOfCombinations(couponSize, normalColumnAmount);
                }
            });
            return combinations;
        };
        this.getBetTotalCost = function () {
            var cost = 0;
            var tickets = _this.ticketSelectedItems;
            tickets.forEach(function (ticket) {
                cost += _this.getTicketTotalCost(ticket);
            });
            return cost;
        };
        this.getTicketTotalCost = function (ticket) {
            var financialRule = _this.getGameFinancialRuleFromTierName(ticket.tierName);
            var ticketPrices = financialRule.ticketPrices.length > 1
                ? financialRule.ticketPrices.find(function (t) { return t.isDefault; })
                : financialRule.ticketPrices[0];
            var linePrice = +GameUtilities.getLinePrice(ticketPrices.amounts, 'amount', _this.currencyCode);
            var combinations = _this.getTicketCombinationSize(ticket);
            return linePrice * _this.consecutiveDraws * combinations;
        };
        this.getNumberOfCombinations = function (listSize, sample) {
            return _this.factorial(listSize) / (_this.factorial(sample) * _this.factorial(listSize - sample));
        };
        this.getGameFinancialRuleFromTierName = function (tierName) {
            var game = _this.gameService.games.find(function (g) { return g.name == tierName; });
            return _this.gameFinancialRulesService.gameFinancialRules.find(function (gfr) { return gfr.gameID == game.gameID; });
        };
        this.factorial = function (num) {
            var rval = 1;
            for (var i = 2; i <= num; i++)
                rval = rval * i;
            return rval;
        };
    }
    PurchasePrintComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentGameGroup = this.gameService.getGame();
        this.localeID = this.appLangService.selectedLocaleID;
        this.currencyCode = this.authService.authTokenData.payload.CurrencyCode;
        this.ticketSelectedItems = this.buyTicketService.ticketSelectedItems;
        this.games = this.gameService.games.map(function (g) {
            return {
                gameID: g.gameID,
                tier: g.name,
                multiplier: g.multiplier,
                isDefault: g.isDefault
            };
        }).sort(function (g1, g2) { return g1.multiplier - g2.multiplier; });
        this.selectedGames = [this.games[0]];
        this.linePrice = this.getLinePrice();
        this.maxWin = this.getMaxWin();
        this.canShowMaxWin = this.capabilities.getCapability('showMaxWin');
        this.setEmptyPickerArray(this.buyTicketService.buyTicketGameData.gameRule.boards);
        this.tenantName = this.authService.authTokenData.payload.TenantName;
        this.betRequiresComment = this.authService.authTokenData.payload.BetRequiresComment;
        this.ticketSelectItemsSubscription = this.buyTicketService.ticketSelectedItemsChange.subscribe(function () {
            _this.refreshTicketCost();
        });
        this.consecutiveDrawsSubscription = this.buyTicketService.consecutiveDrawsChange.subscribe(function () {
            _this.consecutiveDraws = _this.buyTicketService.consecutiveDraws;
            _this.selectedConsecutiveDraws = [_this.consecutiveDrawsItems.find(function (d) { return d.numOfDraws === _this.consecutiveDraws; })];
            _this.refreshTicketCost();
        });
        this.gameFinancialRuleChangeSubscription = this.buyTicketService.gameFinancialRuleChange.subscribe(function (game) {
            _this.linePrice = _this.getLinePrice();
            _this.refreshTicketCost();
            _this.maxWin = _this.getMaxWin();
        });
        this.isQuickBuyReadySubscription = this.buyTicketService.isQuickBuyReadyChange.subscribe(function (isReady) {
            _this.isQuickBuyReady = isReady;
            if (isReady) {
                if (_this.betRequiresComment) {
                    _this.appOverlaysService.loadOverlay(CommentModalComponent, function (commentModalComponent) {
                        commentModalComponent.comment.subscribe(function (c) {
                            _this.comment = _this.buyTicketService.comment;
                            _this.appOverlaysService.removeOverlay();
                            _this.purchaseAndPrint();
                            _this.buyTicketService.isQuickBuyReady = false;
                            _this.isQuickBuyReady = false;
                        });
                    });
                }
                else {
                    _this.purchaseAndPrint();
                    _this.buyTicketService.isQuickBuyReady = false;
                    _this.isQuickBuyReady = false;
                }
            }
        });
        this.gameService.selectedGameChange.subscribe(function (g) {
            _this.selectedGames = [{
                    gameID: g.gameID,
                    tier: g.name,
                    multiplier: g.multiplier,
                    isDefault: g.isDefault
                }];
        });
        var gameRule = this.buyTicketService.buyTicketGameData.gameRule;
        this.gameRuleBoards = gameRule.boards;
        this.comboBetsAllowed = this.capabilities.getCapability('comboBets');
        this.multiBetsAllowed = this.capabilities.getCapability('multiBets');
    };
    PurchasePrintComponent.prototype.ngOnDestroy = function () {
        if (this.ticketSelectItemsSubscription !== undefined) {
            this.ticketSelectItemsSubscription.unsubscribe();
        }
        if (this.consecutiveDrawsSubscription !== undefined) {
            this.consecutiveDrawsSubscription.unsubscribe();
        }
        if (this.isQuickBuyReadySubscription !== undefined) {
            this.isQuickBuyReadySubscription.unsubscribe();
        }
    };
    PurchasePrintComponent.prototype.remove = function (index) {
        this.buyTicketService.removePickedItems(index);
    };
    PurchasePrintComponent.prototype.removeAllPickedItems = function () {
        this.buyTicketService.removePickedItems();
    };
    PurchasePrintComponent.prototype.consecutiveDrawsChange = function (consecutiveDraws) {
        this.buyTicketService.consecutiveDraws = consecutiveDraws[0].numOfDraws;
    };
    PurchasePrintComponent.prototype.purchaseAndPrint = function () {
        var _a;
        var _this = this;
        if (!this.isBetLoading) {
            this.isBetLoading = true;
            if (this.hasEnoughBalance(this.ticketCost)) {
                var multiDrawsObj_1 = new BuyTicketMultiDraws();
                multiDrawsObj_1.multiDraws = this.consecutiveDraws;
                var bets_1 = [];
                this.buyTicketService.ticketSelectedItems.forEach(function (ticket, index) {
                    var gameFinancialRule = _this.getGameFinancialRuleFromTierName(ticket.tierName);
                    var ticketPrices = gameFinancialRule.ticketPrices.length > 1
                        ? gameFinancialRule.ticketPrices.find(function (t) { return t.isDefault; })
                        : gameFinancialRule.ticketPrices[0];
                    var linePrice = +GameUtilities.getLinePrice(ticketPrices.amounts, 'amount', _this.currencyCode);
                    var betData = new BuyTicketData(gameFinancialRule.gameID, [ticket], undefined, linePrice, index === 0, true, multiDrawsObj_1, [], undefined, 0, undefined, false, undefined, true, false, "lottery", _this.authService.authTokenData.token_type + " " + _this.authService.authTokenData.access_token, _this.comment);
                    bets_1.push(betData);
                });
                // const betData = new BuyTicketData(
                // 	this.gameService.currentGame.gameID,
                // 	this.buyTicketService.ticketSelectedItems,
                // 	undefined,
                // 	this.linePrice,
                // 	true,
                // 	true,
                // 	multiDrawsObj,
                // 	[],
                // 	undefined,
                // 	0,
                // 	undefined,
                // 	false,
                // 	undefined,
                // 	true,
                // 	false,
                // 	`lottery`,
                // 	`${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`,
                // 	this.comment
                // );
                (_a = this.buyButtonComponent).buy.apply(_a, tslib_1.__spread(bets_1));
            }
            else {
                this.isBetLoading = false;
                this.appOverlaysService.loadOverlay(AlertComponent, function (alertComponent) {
                    alertComponent.type = 'error';
                    alertComponent.contentText = _this.translateService.instant('purchase-print.not-enough-balance');
                });
            }
        }
    };
    PurchasePrintComponent.prototype.onPlaceBet = function (buyButtonEmit) {
        this.isBetLoading = false;
        if (buyButtonEmit.isSuccessful) {
            this.agentBalanceService.balance = buyButtonEmit.placeBetResponse.balance.balance;
            this.purchasedTickets = this.getPurchasedTicketData(buyButtonEmit.placeBetResponse);
            this.buyTicketService.resetReprints();
            this.createPrintTicketOverlay();
            this.resetCouponPicker();
        }
        else {
            this.errorHandlerService.handleError(buyButtonEmit.error, buyButtonEmit.errorMsg);
        }
    };
    PurchasePrintComponent.prototype.selectedGameChange = function (selectedGames) {
        this.selectedGames = selectedGames;
        this.gameService.setCurrentGame(selectedGames[0].gameID);
        // this.buyTicketService.buyTicketGameData = {
        // 	gameGroupCode: this.gameService.currentGame.gameGroupCode,
        // 	isQuickbuy: false,
        // };
    };
    PurchasePrintComponent.prototype.addRandom = function () {
        this.buyTicketService.addRandom();
    };
    PurchasePrintComponent.prototype.pickNumbers = function () {
        this.buyTicketService.isMobilePickNumbers = true;
        this.scrollIntoView.emit();
    };
    PurchasePrintComponent.prototype.setEmptyPickerArray = function (gameRuleBoards) {
        this.emptyPicker = gameRuleBoards.map(function (b) {
            return new Array(b.numColumnsCoupon);
        });
    };
    PurchasePrintComponent.prototype.createPrintTicketOverlay = function () {
        var _this = this;
        this.appOverlaysService.loadOverlay(PrintTicketComponent, function (componentInstance) {
            componentInstance.couponToPrint = {
                maxWin: _this.maxWin,
                tickets: _this.purchasedTickets
            };
        });
    };
    PurchasePrintComponent.prototype.onCommentUpdate = function (comment) {
        this.comment = comment;
        this.buyTicketService.comment = comment;
    };
    PurchasePrintComponent.prototype.resetCouponPicker = function () {
        this.buyTicketService.removePickedItems();
        this.buyTicketService.consecutiveDraws = 1;
    };
    PurchasePrintComponent.prototype.getLinePrice = function () {
        var financialRule = this.buyTicketService.buyTicketGameData.gameFinancialRule;
        var ticketPrices;
        if (financialRule.ticketPrices.length > 0) {
            ticketPrices = financialRule.ticketPrices.find(function (t) { return t.isDefault; });
            if (ticketPrices === undefined) {
                ticketPrices = financialRule.ticketPrices[0];
            }
        }
        return +GameUtilities.getLinePrice(ticketPrices.amounts, 'amount', this.currencyCode);
    };
    PurchasePrintComponent.prototype.refreshTicketCost = function () {
        this.ticketCost = this.getBetTotalCost();
        this.combinations = this.getBetCombinationSize();
        this.maxWin = this.getMaxWin();
    };
    PurchasePrintComponent.prototype.getPurchasedTicketData = function (placeBetResponse) {
        // only one ticket can be bought from this component since a player can bet only on one game
        var _this = this;
        var purchasedTickets = [];
        var coupon = placeBetResponse.coupon;
        var tickets = coupon.tickets;
        tickets.forEach(function (ticket) {
            var participations = ticket.ticketEntries[0].participations;
            var ticketCost = ticket.ticketCost;
            var drawsFromTo = [];
            var selectedNumbers = [];
            participations = participations.sort(function (a, b) { return a.draw.drawNumber - b.draw.drawNumber; });
            var firstDrawDate = new Date(participations[0].draw.drawDate);
            drawsFromTo.push(placeBetResponse.drawNumberFrom);
            if (placeBetResponse.drawNumberFrom !== placeBetResponse.drawNumberTo) {
                drawsFromTo.push(placeBetResponse.drawNumberTo);
            }
            selectedNumbers = ticket.ticketEntries.map(function (te) {
                var boards = te.numbers.split('|');
                return boards.map(function (board) { return board.split(','); });
            });
            var friendlyCouponIdentifier = '';
            for (var i = 0; i < coupon.couponIdentifier.length; i += 4) {
                friendlyCouponIdentifier += coupon.couponIdentifier.substr(i, 4) + " ";
            }
            var game = _this.gameService.games.find(function (g) { return g.gameID === ticket.gameID; });
            purchasedTickets.push({
                agentID: _this.authService.authTokenData.payload.RetailAgentID,
                termsAndConditions: _this.authService.authTokenData.payload.TermsAndConditions,
                tenantName: _this.tenantName,
                gameGroupCode: _this.currentGameGroup.code,
                gameName: _this.currentGameGroup.name,
                gameTier: game.name,
                isDefaultGame: game.isDefault,
                currencyCode: coupon.currencyCode,
                timestamp: placeBetResponse.coupon.timestamp,
                couponID: coupon.couponIdentifier,
                couponIdentifier: coupon.couponIdentifier,
                friendlyCouponIdentifier: friendlyCouponIdentifier,
                ticketCost: ticketCost,
                ticketValidForDraws: _this.consecutiveDraws,
                selectedNumbers: selectedNumbers,
                drawsFromTo: drawsFromTo,
                firstDrawDate: firstDrawDate,
                comment: _this.buyTicketService.comment
            });
        });
        return purchasedTickets;
    };
    PurchasePrintComponent.prototype.hasEnoughBalance = function (ticketPrice) {
        return ticketPrice <= this.agentBalanceService.balance;
    };
    return PurchasePrintComponent;
}());
export { PurchasePrintComponent };
