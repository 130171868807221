import { Subject, BehaviorSubject } from 'rxjs';
import { GameFinancialRule, GameFinancialRulesService, GameService, TicketSelectedItems } from 'helio-games-core';
import * as i0 from "@angular/core";
import * as i1 from "helio-games-core";
var BuyTicketService = /** @class */ (function () {
    function BuyTicketService(gameService, gameFinancialRulesService) {
        var _this = this;
        this.gameService = gameService;
        this.gameFinancialRulesService = gameFinancialRulesService;
        this.ticketSelectedItems = [];
        this.reprints = new BehaviorSubject(0);
        this._reprints = 0;
        this.commentSubject = new Subject();
        this.consecutiveDrawsValue = 1;
        this.isQuickBuyReadyValue = false;
        this.isMobilePickNumbersValue = false;
        this.gameFinancialRuleChangeSubject = new Subject();
        this.ticketSelectedItemsChangeSubject = new Subject();
        this.consecutiveDrawsChangeSubject = new Subject();
        this.isQuickBuyReadyChangeSubject = new Subject();
        this.addRandomSubject = new Subject();
        this.pickNumbersSubject = new Subject();
        this.selectedGameSubscription = this.gameService.selectedGameChange.subscribe(function (game) {
            _this.buyTicketGameDataValue.gameFinancialRule = _this.gameFinancialRulesService.gameFinancialRules.find(function (gfr) { return gfr.gameID == game.gameID; });
            _this.gameFinancialRuleChangeSubject.next(_this.buyTicketGameDataValue.gameFinancialRule);
        });
    }
    BuyTicketService.prototype.addPickedItems = function (newPickedItems) {
        this.ticketSelectedItems.push(newPickedItems);
        this.ticketSelectedItemsChangeSubject.next();
    };
    BuyTicketService.prototype.removePickedItems = function (index) {
        if (index === undefined) {
            this.ticketSelectedItems.splice(0);
        }
        else {
            this.ticketSelectedItems.splice(index, 1);
        }
        this.ticketSelectedItemsChangeSubject.next();
    };
    Object.defineProperty(BuyTicketService.prototype, "ticketSelectedItemsChange", {
        get: function () {
            return this.ticketSelectedItemsChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "consecutiveDrawsChange", {
        get: function () {
            return this.consecutiveDrawsChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "isQuickBuyReadyChange", {
        get: function () {
            return this.isQuickBuyReadyChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "gameFinancialRuleChange", {
        get: function () {
            return this.gameFinancialRuleChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "commentUpdate", {
        get: function () {
            return this.commentSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "addRandomChange", {
        get: function () {
            return this.addRandomSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "pickNumbersChange", {
        get: function () {
            return this.pickNumbersSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "buyTicketGameData", {
        get: function () {
            return this.buyTicketGameDataValue;
        },
        set: function (newBuyTicketGameData) {
            this.buyTicketGameDataValue = newBuyTicketGameData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "consecutiveDraws", {
        get: function () {
            return this.consecutiveDrawsValue;
        },
        set: function (newConsecutiveDrawsValue) {
            this.consecutiveDrawsValue = newConsecutiveDrawsValue;
            this.consecutiveDrawsChangeSubject.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "isQuickBuyReady", {
        get: function () {
            return this.isQuickBuyReadyValue;
        },
        set: function (newIsQuickBuyReadyValue) {
            this.isQuickBuyReadyValue = newIsQuickBuyReadyValue;
            this.isQuickBuyReadyChangeSubject.next(this.isQuickBuyReadyValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BuyTicketService.prototype, "comment", {
        get: function () {
            return this._comment;
        },
        set: function (c) {
            this._comment = c;
            this.commentSubject.next(c);
        },
        enumerable: true,
        configurable: true
    });
    BuyTicketService.prototype.addRandom = function () {
        this.addRandomSubject.next();
    };
    Object.defineProperty(BuyTicketService.prototype, "isMobilePickNumbers", {
        get: function () {
            return this.isMobilePickNumbersValue;
        },
        set: function (isMobilePickNumbers) {
            this.isMobilePickNumbersValue = isMobilePickNumbers;
            this.pickNumbersSubject.next(isMobilePickNumbers);
        },
        enumerable: true,
        configurable: true
    });
    BuyTicketService.prototype.reprint = function () {
        this._reprints++;
        this.reprints.next(this._reprints);
        localStorage.setItem('ticket-reprints', this._reprints.toFixed(0));
    };
    BuyTicketService.prototype.resetReprints = function () {
        this._reprints = 0;
        this.reprints.next(this._reprints);
        localStorage.setItem('ticket-reprints', '0');
    };
    BuyTicketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BuyTicketService_Factory() { return new BuyTicketService(i0.ɵɵinject(i1.GameService), i0.ɵɵinject(i1.GameFinancialRulesService)); }, token: BuyTicketService, providedIn: "root" });
    return BuyTicketService;
}());
export { BuyTicketService };
