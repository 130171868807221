import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AlertComponent } from '../../alert';
var ScannedTicketComponent = /** @class */ (function () {
    function ScannedTicketComponent(agentPlaceBetService, agentBalanceService, appOverlaysService, authService, errorHandlerService, translateService, appLangService, cancelTicketService, capabilities) {
        this.agentPlaceBetService = agentPlaceBetService;
        this.agentBalanceService = agentBalanceService;
        this.appOverlaysService = appOverlaysService;
        this.authService = authService;
        this.errorHandlerService = errorHandlerService;
        this.translateService = translateService;
        this.appLangService = appLangService;
        this.cancelTicketService = cancelTicketService;
        this.capabilities = capabilities;
        this.fade = true;
        this.cancelTicketEnabled = false;
        this.hasParticipationsToPay = false;
        this.isCancelLoading = false;
        this.isMarkAsPaidLoading = false;
        this.now = new Date();
        this.participationsToPay = [];
    }
    ScannedTicketComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localeID = this.appLangService.selectedLocaleID;
        var canCancel = this.capabilities.getCapability('cancel');
        this.cancelTicketEnabled = canCancel && (this.now <= this.scannedTicket.cancellationLimit);
        if (this.cancelTicketEnabled) {
            this.intervalTimer = setInterval(function () {
                _this.now = new Date();
                _this.cancelTicketEnabled = _this.now <= _this.scannedTicket.cancellationLimit;
                if (!_this.cancelTicketEnabled) {
                    clearInterval(_this.intervalTimer);
                }
            }, 1000);
        }
        this.participationsToPay = this.getParticipationsToPay();
        this.hasParticipationsToPay = (this.participationsToPay.length > 0);
    };
    ScannedTicketComponent.prototype.ngOnDestroy = function () {
        if (this.intervalTimer !== undefined) {
            clearInterval(this.intervalTimer);
        }
    };
    ScannedTicketComponent.prototype.closeOverlay = function () {
        this.appOverlaysService.removeOverlay();
    };
    ScannedTicketComponent.prototype.markAsPaid = function () {
        var _a;
        var _this = this;
        if (this.participationsToPay.length > 0 && !this.isMarkAsPaidLoading) {
            this.isMarkAsPaidLoading = true;
            var participationIDs_1 = this.participationsToPay.map(function (p) { return p.participationID; });
            var authHeaderValue = this.authService.authTokenData.token_type + " " + this.authService.authTokenData.access_token;
            (_a = this.agentPlaceBetService).participationPayout.apply(_a, tslib_1.__spread([this.scannedTicket.couponID,
                authHeaderValue,
                this.scannedTicket.totalWinnings], participationIDs_1)).subscribe(function (res) {
                _this.isMarkAsPaidLoading = false;
                if (res.isSuccess) {
                    _this.agentBalanceService.balance = res.balance.balance;
                    _this.updateAfterMarkAsPaid(participationIDs_1);
                    _this.appOverlaysService.loadOverlay(AlertComponent, function (alertComponent) {
                        alertComponent.type = 'info';
                        alertComponent.contentText = _this.translateService.instant('scanned-ticket.messages.winnings-paid-success');
                    });
                }
                else {
                    _this.appOverlaysService.loadOverlay(AlertComponent, function (alertComponent) {
                        alertComponent.type = 'error';
                        alertComponent.contentText = _this.translateService.instant('scanned-ticket.messages.winnings-paid-error');
                    });
                }
            }, function (error) {
                _this.isMarkAsPaidLoading = false;
                _this.errorHandlerService.handleError(error, _this.translateService.instant('scanned-ticket.messages.winnings-paid-error'));
            });
        }
    };
    ScannedTicketComponent.prototype.cancelTicketConfirm = function () {
        var _this = this;
        this.appOverlaysService.loadOverlay(AlertComponent, function (alertComponent) {
            alertComponent.type = 'info';
            alertComponent.contentText = _this.translateService.instant('scanned-ticket.cancel-ticket-confirm');
            alertComponent.hasConfirmBtn = true;
            alertComponent.confirmBtnText = _this.translateService.instant('general.alerts.yes-btn');
            alertComponent.confirmBtnClick.subscribe(function () {
                _this.cancelTicket();
                _this.appOverlaysService.removeOverlay();
            });
            alertComponent.closeBtnText = _this.translateService.instant('general.alerts.no-btn');
        });
    };
    ScannedTicketComponent.prototype.cancelTicket = function () {
        var _this = this;
        if (!this.isCancelLoading) {
            this.isCancelLoading = true;
            this.cancelTicketService.cancelTicket(this.scannedTicket.couponID)
                .subscribe(function (isSuccess) {
                _this.isCancelLoading = false;
                if (isSuccess) {
                    _this.cancelTicketEnabled = false;
                    if (_this.intervalTimer !== undefined) {
                        clearInterval(_this.intervalTimer);
                    }
                    _this.appOverlaysService.loadOverlay(AlertComponent, function (alertComponent) {
                        alertComponent.type = 'info';
                        alertComponent.contentText = _this.translateService.instant('scanned-ticket.messages.ticket-cancel-success');
                    });
                }
                else {
                    _this.appOverlaysService.loadOverlay(AlertComponent, function (alertComponent) {
                        alertComponent.type = 'error';
                        alertComponent.contentText = _this.translateService.instant('scanned-ticket.messages.ticket-cancel-error');
                    });
                }
            }, function (error) {
                _this.isCancelLoading = false;
                _this.errorHandlerService.handleError(error, _this.translateService.instant('scanned-ticket.messages.ticket-cancel-error'));
            });
        }
    };
    ScannedTicketComponent.prototype.updateAfterMarkAsPaid = function (participationIDs) {
        var participationsToUpdate = this.scannedTicket.participations.filter(function (p) {
            return participationIDs.indexOf(p.participationID) !== -1;
        });
        participationsToUpdate.forEach(function (p) {
            p.isPaidOut = true;
        });
        this.scannedTicket.totalWinnings = 0;
        this.hasParticipationsToPay = false;
    };
    ScannedTicketComponent.prototype.getParticipationsToPay = function () {
        return this.scannedTicket.participations.filter(function (p) {
            return p.isDrawClosed && p.isPaidOut === false && p.winAuthorisationStatus === 0;
        });
    };
    return ScannedTicketComponent;
}());
export { ScannedTicketComponent };
