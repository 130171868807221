import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
var StatsListComponent = /** @class */ (function () {
    function StatsListComponent(agentSalesStatsService, authService, translate) {
        var _this = this;
        this.agentSalesStatsService = agentSalesStatsService;
        this.authService = authService;
        this.translate = translate;
        this.stats = [];
        this.onStatsReceived = new EventEmitter();
        this.isLoading = false;
        this.getDataError = false;
        this.getStats = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var authToken, allGames, stats, totalSales_1, totalPayouts_1, totalCancelled_1, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isLoading) return [3 /*break*/, 5];
                        this.isLoading = true;
                        this.getDataError = false;
                        authToken = this.authService.authTokenData.token_type + " " + this.authService.authTokenData.access_token;
                        return [4 /*yield*/, this.translate.get('sales-stats.all-games').toPromise()];
                    case 1:
                        allGames = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.agentSalesStatsService.getStatistics(this.dateFrom, this.dateTo, authToken).toPromise()];
                    case 3:
                        stats = _a.sent();
                        this.isLoading = false;
                        if (stats.retailAgentStats.length > 0) {
                            totalSales_1 = 0;
                            totalPayouts_1 = 0;
                            totalCancelled_1 = 0;
                            stats.retailAgentStats.forEach(function (stat) {
                                totalSales_1 += stat.totalSales;
                                totalPayouts_1 += stat.totalPayouts;
                                totalCancelled_1 += stat.totalCancelled;
                            });
                            stats.retailAgentStats.unshift({
                                gameName: allGames,
                                gameGroupCode: '',
                                totalSales: totalSales_1,
                                totalPayouts: totalPayouts_1,
                                totalCancelled: totalCancelled_1
                            });
                        }
                        this.onStatsReceived.emit(stats.retailAgentStats);
                        this.stats = stats.retailAgentStats;
                        return [2 /*return*/, stats];
                    case 4:
                        ex_1 = _a.sent();
                        this.isLoading = false;
                        this.getDataError = true;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
    }
    StatsListComponent.prototype.ngOnInit = function () {
        this.getStats();
    };
    StatsListComponent.prototype.ngOnChanges = function (changes) {
        if (changes !== undefined) {
            if (!changes['dateFrom'].isFirstChange() && !changes['dateTo'].isFirstChange()) {
                if (changes['dateFrom'].currentValue !== changes['dateFrom'].previousValue ||
                    changes['dateTo'].currentValue !== changes['dateTo'].previousValue) {
                    this.getStats();
                }
            }
        }
    };
    return StatsListComponent;
}());
export { StatsListComponent };
