var AppLangService = /** @class */ (function () {
    function AppLangService() {
        this.currentSelectedLanguage = 'en';
        this.currentSelectedLocaleID = 'en-GB';
    }
    Object.defineProperty(AppLangService.prototype, "selectedLanguage", {
        get: function () {
            return this.currentSelectedLanguage;
        },
        set: function (newSelectedLanguage) {
            localStorage.setItem('agentAppLang', newSelectedLanguage);
            this.currentSelectedLanguage = newSelectedLanguage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppLangService.prototype, "selectedLocaleID", {
        get: function () {
            return this.currentSelectedLocaleID;
        },
        set: function (newSelectedLocaleID) {
            this.currentSelectedLocaleID = newSelectedLocaleID;
        },
        enumerable: true,
        configurable: true
    });
    return AppLangService;
}());
export { AppLangService };
