import * as tslib_1 from "tslib";
var ScannedTicket = /** @class */ (function () {
    function ScannedTicket(scannedAgentCoupon, cancellationLimit) {
        this.totalWinnings = 0;
        this.ticketCost = 0;
        this.participations = [];
        this.couponID = scannedAgentCoupon.couponIdentifier;
        this.currencyCode = scannedAgentCoupon.currencyCode;
        this.gameName = scannedAgentCoupon.tickets[0].gameName;
        this.timestamp = new Date(scannedAgentCoupon.timestamp);
        this.ticketCost = scannedAgentCoupon.tickets[0].ticketCost;
        this.comment = scannedAgentCoupon.comment;
        this.setCancellationDate(cancellationLimit);
        this.saveScannedTicketData(scannedAgentCoupon);
    }
    ScannedTicket.prototype.saveScannedTicketData = function (agentCoupon) {
        var _this = this;
        var ticketEntries = agentCoupon.tickets.map(function (t) { return t.ticketEntries[0]; });
        ticketEntries = ticketEntries.sort(function (a, b) { return a.ticketEntryNum - b.ticketEntryNum; });
        ticketEntries.forEach(function (te) {
            var _a;
            // save participation details
            var ticketParticipations = te.participations.map(function (p) {
                return _this.getParticipationDetails(p, te);
            });
            (_a = _this.participations).push.apply(_a, tslib_1.__spread(ticketParticipations));
        });
        this.participations.sort(function (a, b) { return a.drawNumber - b.drawNumber; });
    };
    ScannedTicket.prototype.getParticipationDetails = function (participation, ticketEntry) {
        var isDrawClosed = (participation.draw.drawStatusID === 2 || participation.draw.drawStatusID === 3);
        var isDrawResultPending = (isDrawClosed && (participation.draw.results === null || participation.draw.results === undefined));
        var drawResultNumbers = [];
        if (isDrawClosed && !isDrawResultPending) {
            var drawResultBoards = participation.draw.results.split('|');
            drawResultNumbers = drawResultBoards.map(function (b) { return b.split(','); });
        }
        var winAmount = 0;
        var winAuthorisationStatus = 0;
        if (participation.winnings !== undefined && participation.winnings !== null) {
            winAmount = participation.winnings.amount;
            winAuthorisationStatus = participation.winnings.authorisationStatusID;
            if (!participation.winnings.paidOut && winAuthorisationStatus === 0) {
                this.totalWinnings += winAmount;
            }
        }
        var isPaidOut;
        if (participation.winnings !== undefined) {
            isPaidOut = participation.winnings.paidOut;
        }
        return {
            participationID: participation.participationID,
            currencyCode: this.currencyCode,
            drawDate: new Date(participation.draw.drawDate),
            drawResult: drawResultNumbers,
            drawNumber: participation.draw.drawNumber,
            isDrawClosed: isDrawClosed,
            isDrawResultPending: isDrawResultPending,
            selectedNumbers: this.getParticipationNumbers(ticketEntry, drawResultNumbers),
            winAmount: winAmount,
            isPaidOut: isPaidOut,
            winAuthorisationStatus: winAuthorisationStatus,
            ticketEntryNum: ticketEntry.ticketEntryNum
        };
    };
    ScannedTicket.prototype.getParticipationNumbers = function (ticketEntry, drawResult) {
        var boards = ticketEntry.numbers.split('|');
        var boardNumbers = boards.map(function (board) { return board.split(','); });
        var hasDrawResults = drawResult.length > 0;
        return boardNumbers.map(function (board, i) {
            return board.map(function (boardNum) {
                return {
                    number: +boardNum,
                    isMatched: hasDrawResults && (drawResult[i].indexOf(boardNum) !== -1)
                };
            });
        });
    };
    ScannedTicket.prototype.setCancellationDate = function (cancellationLimit) {
        this.cancellationLimit = new Date(this.timestamp);
        this.cancellationLimit = new Date(this.cancellationLimit.setSeconds(this.cancellationLimit.getSeconds() + cancellationLimit));
    };
    return ScannedTicket;
}());
export { ScannedTicket };
