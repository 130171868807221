import * as tslib_1 from "tslib";
import { CurrencyPipe } from "@angular/common";
var CurrencyWithSpacePipe = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencyWithSpacePipe, _super);
    function CurrencyWithSpacePipe() {
        var _this = _super.call(this, getLang()) || this;
        _this.transform = function (value, currencyCode, display, digitsInfo, locale) {
            var renderedCurrency = _super.prototype.transform.call(_this, value, currencyCode, display, digitsInfo, locale);
            var testCurrency = _super.prototype.transform.call(_this, 0, currencyCode, display, digitsInfo, locale);
            var code = testCurrency.substring(0, testCurrency.length - 1);
            if (currencyCode.length > 1) {
                renderedCurrency = renderedCurrency.replace(' ', '');
                renderedCurrency = renderedCurrency.indexOf(code) === 0
                    ? renderedCurrency.replace(code, code + " ")
                    : renderedCurrency.replace(code, " " + code);
            }
            return renderedCurrency;
        };
        return _this;
    }
    return CurrencyWithSpacePipe;
}(CurrencyPipe));
export { CurrencyWithSpacePipe };
export var getLang = function () {
    if (navigator.languages != undefined)
        return navigator.languages[0];
    return navigator.language;
};
